import React from "react";
import Moment from 'react-moment';
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import PropTypes from "prop-types";

const PostCardContainer = styled(Link)`
    border: 1px solid ${colors.grey200};
    border-radius: 3px;
    text-decoration: none;
    color: currentColor;
    background: ${colors.yellow};
    display: flex;
    flex-direction: column;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    transition: all 150ms ease-in-out;

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;
        cursor: pointer;

        .PostCardAction {
            color: ${colors.red};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }
    }
`

const PostCardContent = styled("div")`
    padding: 2.25em 2.5em 2.25em 2.5em;
    color: ${colors.black};
`

const PostCardImage = styled("div")`
    overflow: hidden;
    height: 300px;
    display: flex;
    flex-direction: column;

    img {
        width: 100%;
    }
`

const PostCategory = styled("h6")`
    font-weight: 600;
    color: ${colors.green};
`

const PostTitle = styled("h3")`
    margin: 0;
    margin-top: 0.5em;
`

const PostMetas = styled("div")`
    display: flex;
    align-items: center;
    margin-top: 1.5em;
    justify-content: space-between;
    font-size: 0.85em;
    color: ${colors.green};
`

const PostDate = styled("div")`
    margin: 0;
`

const PostDescription = styled("div")`
    margin-top: 2em;
    margin-bottom: 2em;

    p:last-of-type {
        margin: 0;
    }
`

const PostCardAction = styled("div")`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }
`

const PostCard = ({ category, date, title, image, description, uid}) => (
    <PostCardContainer className="BlogPostCard" to={`/naujienos/${uid}`}>
        <PostCardImage>
            <img src={image.desktop.url} alt={image.alt}/>
        </PostCardImage>
        <PostCardContent>
            <PostCategory>
                {(category) ? category[0].text : ''}
            </PostCategory>
            <PostTitle>
                {(title) ? title[0].text : '' }
            </PostTitle>
            <PostDescription>
                {(description) ? RichText.render(description) : ''}
            </PostDescription>
            <PostCardAction className="PostCardAction">
                Skaityti daugiau <span>&#8594;</span>
            </PostCardAction>
            <PostMetas>
                <PostDate>
                    <Moment format="YYYY MM D">{(date) ? date : ''}</Moment>
                </PostDate>
            </PostMetas>
        </PostCardContent>
    </PostCardContainer>
)

export default PostCard;

PostCard.propTypes = {
    category: PropTypes.array,
    date: PropTypes.string.isRequired,
    title: PropTypes.array.isRequired,
    image: PropTypes.object.isRequired,
    description: PropTypes.array,
    uid: PropTypes.string.isRequired
}